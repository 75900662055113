<script setup lang="ts">
import { useField } from 'vee-validate'
import type { IBaseCombobox } from './BaseCombobox.vue'
import BaseCombobox from './BaseCombobox.vue'

export interface IFormCombobox extends IBaseCombobox {
  rules?: string
  initialValue?: any
}

const props = withDefaults(defineProps<IFormCombobox>(), {
  initialValue: undefined,
})

const { name, rules, initialValue } = toRefs(props)

const { value, errorMessage } = useField<any>(name, rules, {
  initialValue,
})
</script>

<template>
  <BaseCombobox
    v-bind="props"
    :value="value"
    :error="errorMessage"
  />
</template>
