<script setup lang="ts">
import BaseCombobox from './BaseCombobox.vue'
import type { IFormCombobox } from './FormCombobox.vue'
import FormCombobox from './FormCombobox.vue'

export interface ICombobox extends IFormCombobox {
  useForm?: boolean
  tooltip?: string
}

const props = withDefaults(defineProps<ICombobox>(), {
  useForm: true,
  serverSide: false,
  loading: false,
})

const model = defineModel()
</script>

<template>
  <component
    :is="props.useForm ? FormCombobox : BaseCombobox"
    v-bind="props"
    v-model="model"
  />
</template>
